<template>
  <action-menu-wrapper :items="items" :offset="offset" teleport />

  <preset-modal
    v-if="showPresetModal"
    :close="() => (showPresetModal = false)"
    :preset-data="model"
    is-edit-mode
  />

  <confirm-modal
    v-if="showConfirmDeleteModal"
    :title="$t('PRESETS.DELETE_PRESET')"
    :description="$t('PRESETS.DELETE_WARNING', { preset: model.name })"
    confirm-button="Delete"
    confirm-button-type="error"
    :close="() => (showConfirmDeleteModal = false)"
    @confirm="deletePreset"
  />
</template>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import { ref, type PropType } from "vue";
import { useI18n } from "vue-i18n";

import type { PermissionsPreset } from "@/models/permissionsPreset";
import usePermissionsPresetStore from "@/stores/permissionsPreset";

import PresetModal from "@/components/presets/presetModal/PresetModal.vue";
import ActionMenuWrapper, {
  type MenuItem
} from "@/components/ui/ActionMenuWrapperV2.vue";

const props = defineProps({
  model: {
    type: Object as PropType<PermissionsPreset>,
    required: true
  }
});

const offset = { left: -76, top: 43 };

const permissionsPresetStore = usePermissionsPresetStore();
const { t } = useI18n();

const showPresetModal = ref(false);
const showConfirmDeleteModal = ref(false);

const items: MenuItem[] = [
  {
    label: t("COMMON.EDIT"),
    handler: () => (showPresetModal.value = true)
  },
  {
    label: t("COMMON.DUPLICATE"),
    handler: duplicatePreset
  },
  {
    label: t("COMMON.DELETE"),
    handler: () => (showConfirmDeleteModal.value = true)
  }
];

async function deletePreset() {
  await permissionsPresetStore.deletePreset(props.model.id);
}

async function duplicatePreset() {
  await permissionsPresetStore.duplicatePreset({
    ...props.model,
    assigned_client_ids: props.model.assigned_clients.map((client) => client.id)
  });
}
</script>
